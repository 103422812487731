import * as React from 'react';
import DesignServicesCarousel from '../DesignServicesCarousel/DesignServicesCarousel';
import Card from 'styleguide/components/Card/Card';
import IconClock from 'styleguide/icons/IconClock';
import { pricingCards } from 'bundles/App/pages/service/DesignServices/designServicesData';
import { responsiveBreakpoint, ResponsiveBreakpoints } from 'styleguide/styles/layout/responsive';
import useWindowDimensions from 'utils/dimensions';
import A from 'styleguide/components/Links/A';

const PricingSection = ({ setFocus }) => {
  const { width } = useWindowDimensions();
  const currentBreakpoint: ResponsiveBreakpoints = responsiveBreakpoint(width);
  const [currentSlideIndex, setCurrentSlideIndex] = React.useState(0);
  const [slidesToShow, setSlidesToShow] = React.useState(0.8);

  const getSlidesToShowForPricingSection = (): number => {
    if (width < 350) {
      return 1;
    }
    if (width < 400) {
      return 1.1;
    }
    if (width < 560 && width >= 400) {
      return 1.2;
    }
    if (currentBreakpoint === 'sm') {
      return 1.8;
    }
    if (currentBreakpoint === 'md') {
      return 2.5;
    }
    if (currentBreakpoint === 'lg') {
      return 3.2;
    }
    if (width > 1280) {
      return 4;
    }
    return 1;
  };

  React.useEffect(() => {
    setSlidesToShow(getSlidesToShowForPricingSection());
  }, [currentBreakpoint]);

  return (
    <div className="relative mx-auto max-w-8xl pb-36 text-center xl:pb-48">
      <h4 className="heading-heavy-sm mx-auto mb-8">Pricing</h4>
      <DesignServicesCarousel
        controlClassName="right-5"
        setCurrentSlideIndex={setCurrentSlideIndex}
        slidesToShow={slidesToShow}
      >
        {pricingCards.map((card, i: number) =>
          i <= currentSlideIndex + 4 ? (
            <div className="mr-2">
              <Card
                className="mb-1"
                imageClassName="!object-cover"
                image={{ url: card.image.url, alt: card.image.alt, width: 277, height: 306 }}
                title={card.title}
                key={`${card.image.alt}_${i}`}
                linkText="Get quote"
                onClick={setFocus}
                Bubble={
                  <div className="flex flex-col items-end justify-center p-3">
                    {card.bubble?.price && (
                      <div className="paragraph-bold-desktop text-white">From {card.bubble.price}</div>
                    )}
                    {card.bubble?.unit && (
                      <div className="caption text-right text-white">per {card.bubble.unit}</div>
                    )}
                    {card.bubble?.linkText && (
                      <A
                        className="!font-hvMedium !text-sm !text-white"
                        underline="none"
                        href={card.bubble?.linkUrl}
                        color="black"
                      >
                        {card.bubble?.linkText}
                      </A>
                    )}
                  </div>
                }
                Body={
                  <div className="px-4">
                    <div className="paragraph-mobile flex items-center">
                      <IconClock className="mr-1" size="xs" color="dark" />
                      {card.turnaround}
                    </div>
                    {card.info.map((info, index: number) => (
                      <div className="paragraph-mobile mt-2" key={index}>
                        {info}
                      </div>
                    ))}
                  </div>
                }
              />
            </div>
          ) : null,
        )}
      </DesignServicesCarousel>
    </div>
  );
};

export default PricingSection;
