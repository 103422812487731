import * as React from 'react';
import Carousel from 'nuka-carousel';
import { IconArrowLeft, IconArrowRight } from 'styleguide/icons';
import cn from 'classnames';

const DesignServicesCarousel = ({ children, controlClassName = '', setCurrentSlideIndex, slidesToShow }) => (
  <div className="relative mx-auto max-w-7xl pl-6">
    <Carousel
      className="mx-auto"
      wrapAround
      dragThreshold={0.1}
      slidesToShow={slidesToShow}
      afterSlide={(slideIndex: number) => setCurrentSlideIndex(slideIndex)}
      renderBottomLeftControls={({ currentSlide, slideCount }) => (
        <div className="paragraph-bold-desktop relative top-11">
          {currentSlide + 1}/{slideCount}
        </div>
      )}
      renderBottomRightControls={({ currentSlide, previousSlide, nextSlide }) => (
        <div className={cn('relative top-11', controlClassName)}>
          {currentSlide !== 0 && (
            <IconArrowLeft className="mr-6 cursor-pointer" onClick={previousSlide} size="xs" />
          )}
          <IconArrowRight className="cursor-pointer" onClick={nextSlide} size="xs" />
        </div>
      )}
      defaultControlsConfig={{
        nextButtonClassName: 'hidden',
        prevButtonClassName: 'hidden',
        pagingDotsClassName: 'hidden',
      }}
    >
      {children}
    </Carousel>
  </div>
);

export default DesignServicesCarousel;
