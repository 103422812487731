import * as React from 'react';
import cn from 'classnames';

import css from './Checkbox.scss';
import { DEFAULT_SIZE } from '../constants';

const Checkbox = ({ field, id, label, className, size = DEFAULT_SIZE, inputClassName = null, ...props }) => (
  <div className={cn(css.outerWrapper, css[`size--${size}`], 'relative')}>
    <div className={cn('absolute block', className)}>
      <input
        {...field}
        id={id}
        checked={field.value}
        type="checkbox"
        className={cn(css.styledCheckbox, css[`size--${size}`], inputClassName)}
        {...props}
      />
      <label className={cn(css.styledLabel)} htmlFor={id}>
        {label}
      </label>
    </div>
  </div>
);

export default Checkbox;
