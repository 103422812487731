import * as React from 'react';
import cn from 'classnames';
import DividedSection from './DividedSection';
import Divided from './Divided';
import Option from './Option';
import DefaultOption from './DefaultOption';
import AbstractInput from 'styleguide/components/Formik/AbstractInput/AbstractInput';
import OptGroup from './OptGroup';

const Select = ({ field, className, size, center = false, ...props }) => (
  <div className={cn(center && props.inPlaceError ? 'pt-2' : '', 'flex w-full flex-col')}>
    <AbstractInput
      {...field}
      disabled={props.disabled}
      component="select"
      className={className}
      value={field.value}
      size={size || 'md'}
      {...props}
    />
  </div>
);

Select.DividedSection = DividedSection;
Select.Divided = Divided;
Select.Option = Option;
Select.OptGroup = OptGroup;
Select.DefaultOption = DefaultOption;

export default Select;
