import * as React from 'react';
import DesignServicesCarousel from '../DesignServicesCarousel/DesignServicesCarousel';
import { designServicesImages } from 'bundles/App/pages/service/DesignServices/designServicesData';
import Image from 'styleguide/components/Image/Image';
import { responsiveBreakpoint, ResponsiveBreakpoints } from 'styleguide/styles/layout/responsive';
import useWindowDimensions from 'utils/dimensions';

const OurLatestWork = () => {
  const { width } = useWindowDimensions();
  const currentBreakpoint: ResponsiveBreakpoints = responsiveBreakpoint(width);
  const [currentSlideIndex, setCurrentSlideIndex] = React.useState(0);
  const [slidesToShow, setSlidesToShow] = React.useState(0.8);

  const getSlidesToShowForOurLatestWorkSection = (): number => {
    if (currentBreakpoint === 'xxs') {
      return 0.8;
    }
    if (currentBreakpoint === 'xs') {
      return 1.2;
    }
    if (currentBreakpoint === 'sm') {
      return 1.8;
    }
    if (currentBreakpoint === 'md') {
      return 2.4;
    }
    if (currentBreakpoint === 'lg') {
      return 3;
    }
    if (width > 1280) {
      return 3.4;
    }
    return 0.8;
  };

  React.useEffect(() => {
    setSlidesToShow(getSlidesToShowForOurLatestWorkSection());
  }, [currentBreakpoint]);

  return (
    <div className="relative mx-auto max-w-8xl pb-36 pt-24 text-center xl:pb-48 xl:pt-48">
      <h4 className="heading-bold-sm mx-auto">Check out our latest work.</h4>
      <p className="paragraph-medium-desktop mx-auto mb-8 mt-2 max-w-4xl">
        See what we’ve done for our clients.
      </p>
      <DesignServicesCarousel
        controlClassName="-xl:right-5 -2xl:right-8"
        setCurrentSlideIndex={setCurrentSlideIndex}
        slidesToShow={slidesToShow}
      >
        {designServicesImages.map((image, i: number) => (
          <div className="mr-3">
            {i <= currentSlideIndex + 4 ? (
              <Image
                key={`${image.alt}_${i}`}
                className="borders-solid relative h-96 w-96 rounded-lg border border-neutral-200 object-cover p-2 hover:shadow-md"
                width={584}
                height={584}
                alt={image.alt}
                title={image.title}
                url={image.url}
                lazyload
              />
            ) : null}
          </div>
        ))}
      </DesignServicesCarousel>
    </div>
  );
};

export default OurLatestWork;
